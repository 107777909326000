div:has(~div.excludedims>button), div:has(~div.excludedims>button)>span{
    transition: color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

div:has(~div.excludedims>button[data-value=true]){
    color:#6b7280;
    text-decoration: line-through;
}
div:has(+div.excludedims>button[data-value=true])>span{
    color:#6b7280;
    background-color:#6b728060;
}
div:has(+div.excludedims>button[data-value=true])>span>span{
    display: none;
}
div:has(+div.excludedims>button[data-value=true])>span:after{
    content:"Ignore";
    color:#6b7280;

}
div:has(~div.excludedims>button[data-value=true]) svg{
    color:#6b7280;
    text-decoration: line-through;
}
button[role=switch]:focus{
    
    --tw-ring-shadow:#6b7280;
    border-color: #6b7280;
    outline: #6b7280;
}
button[data-value=true]{
    background-color: #6b7280;

}